const urlSocialMedia = [
    {
        site: "LinkedIn",
        url: "https://www.linkedin.com/in/ben-aouad-bilal-602b8a245/",
    },
    {
        site: "Github",
        url: "https://www.github.com/bben-aou",
    },
    {
        site: "Instagram",
        url: "https://www.instagram.com/bilal.b.aouad/",
    }
]
export default urlSocialMedia;